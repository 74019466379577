.PreviewCard {
  background-image: url("/bg_trans.png");
}

.PreviewCardResizableRightHandle {
  background: #ffffff;
  /* box-shadow: 0 1.2rem 3.6rem rgba(0, 0, 0, 0.2); */
  cursor: ew-resize;
  width: 3.2rem !important;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--p-action-primary);
}
