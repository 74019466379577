.Checkbox {
  padding: 0px !important;
  width: 90px !important;
  height: 90px !important;
}

.CheckboxCenter {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.Checkbox .PolarisChoiceControl {
  margin-top: 0px !important;
  margin-right: 0px !important;
}

.Tooltip {
  opacity: 0;
}

.BadgeContainer {
  z-index: 9999;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
